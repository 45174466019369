import { useEffect, useState } from "react";
// import AccountBalanceCard from "../webb/acnt-balancecard";

import { crnc, numc, UserForm } from "../../services/srvc-utilities";
import {  GetSandboxUser, GetProductionUser } from "../../services/srvc-auth-user";

// import {   BusinessAccountBalance } from "../../services/srvc-accounts-realm";
import { useHistory } from "react-router-dom";
import { GetAccountBalance } from "../../services/srvc-accounts-realm";

export default function AccountsBalanceModule() {

  const asset = UserForm() === "sn" ? GetSandboxUser() : GetProductionUser();
  const history = useHistory();
  const [data, setData]=useState({
    acbl: 0, mots: '2021-12-06T12:30:00.000Z'
  });

  const [text,setText] = useState('Account Not Created')
  const [accountinfo,setAccountinfo] = useState(true)

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        const result = (await GetAccountBalance({ 
          data: { user: asset.user, type:'base-account',form:asset.form },
          user: asset.user
        }));
        
        // console.log(result)

        if (result.data) {
          setData({acbl: result.data.balance.number, mots: result.data.modified})
          setAccountinfo(true)
          setText('')
        }
        if(result.data==false){
          setText('Account Not Created')
          setAccountinfo(false)
        }
      }
      fetchData()
    } else {}
  },[asset.user]);

  //console.log(data)



  return (
  <>
    <div className="mb-3">
      <div  className={''}>
        <div className="d-none d-md-block">
        <div className="">
            <p className="text-bold text-small text-color-tone m-0">BALANCE</p>
            <p className="text-lead text-bold m-0 p-0">{crnc(data.acbl/1000000)}</p>
            <p className="text-small text-color-tone m-0">
              {(new Date(data.mots)).toLocaleString()}
            </p>
          </div>
        </div>
      
        <div className="container-fluid back-color-main rounded-wd p-3 text-white d-md-none">
          <div className="d-flex ">
            <div className="me-auto">
              <p className="text-bold text-small m-0 p-0">CREDIT</p>
            </div>

            <div className="text-end">
            <p className="text-bold m-0 p-0">X{crnc(data.acbl/1000000)}</p>
          </div>
        </div>
        <p className="m-0 p-0"><small>{(new Date(data.mots)).toLocaleString()}</small></p>
       </div>
       <p className={accountinfo?'':'text-danger'}>{accountinfo?'':'Account Not Created'}</p>
      </div>
      
      {/* <div className={accountinfo?'d-none':'d-flex p-2 '}>
       <p className="text-danger small me-auto mt-2">{text}</p>
       <button onClick={()=>CreateAccount()} className="p-3 py-1 rounded-wd back-color-wite hitone border-none text-small text-center">Create Account</button>
      </div> */}
      

    </div>
  </>
  )
}