// transfers
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbSpinText from "../webb/webb-spintext";
import ListNoData from "../webb/list-nodata";
import NavsButtonBack from "../webb/navs-button-back";
import NavsButtonNext from "../webb/navs-button-next";

import { crnc, numc, UserForm } from "../../services/srvc-utilities";
import { GetSandboxUser, GetProductionUser } from "../../services/srvc-auth-user";

import { GetTransfersList } from "../../services/srvc-transfers-realm";
import { GetApiTransactionList } from "../../services/srvc-api-realm";

export default function ApiListModule(props) {

  // console.log(props)
  const asset = UserForm() === "sn" ? GetSandboxUser() : GetProductionUser();
  // console.log (asset)
  const [loading,setLoading]=useState(false);
  const history = useHistory();

  const [data, setData] = useState([]);
  const search = props.data;
  const type = props.type
  // console.log(type)
 
  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')


  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        const datx = {
          data: { scrt: asset.user, index:index, items: items, form:asset.form },
          user: asset.user
        }
        
        // //console.log(search)
        const result = await GetApiTransactionList(datx)
        // console.log (result)

        setData(result.data.list);

        // // // index
        setCount(result.data.count);
        setTotal(Math.ceil(result.data.count/items));
        setText(`${(index-1)*items+1} - ${index*items < result.data.count ? index*items : result.data.count} of ${result.data.count}`)


        setLoading(false);
      }
      fetchData()
    } else {}
  },[index, items, search]);


  const NextIndex = async() =>{
    if(total == index) {}
    else {
      setNextIndex(curr+1)
      setIndex(curr+1)
      setCurrentIndex(curr+1)
    }
  }

  const LastIndex = async()=>{
    if(index == 1) {}
    else{
      setLastIndex(curr-1)
      setIndex(curr-1)
      setCurrentIndex(curr-1)
    }
  }

  const handleClick = (item) => {
    // history.push(`/${asset.form.substring(0,2)}/transfers/v/${item}`)
  }


  if (loading){ return ( <> </> ) }
  if (!loading && (!data || data.length === 0)) { return ( <> <ListNoData /> </> ) }

  return (
  <>

  {/* data */}


    <table class="table">
    <thead className="">
        <tr>
        <th>S.No</th>  
        <th scope="col">Name</th>
        <th scope="col">Status</th>
        <th scope="col">Time</th>
        </tr>
    </thead>
    <tbody className="">
    {
    data && data.length > 0 && data.map((item, i) =>(
        <tr className="">
        <td>{((index-1)*items+1) +i }</td>  
        <td>{item.task}</td>
        <td className={item.stat==200 || item.stat==201 ? 'text-success': 'text-danger'}>{item.stat}</td>
        <td>{(new Date(item.crts)).toLocaleString()}</td>
    </tr>
    ))}
        
    
    </tbody>
    </table>


    {/* index */}
    <WebbDividerSmall />
    <div className="">
      <div className="d-flex justify-content-between">

        <div className="" onClick={()=>LastIndex()}>
          <NavsButtonBack />
        </div>

        <div className="">
          <p className="my-2 text-color-tone">{text}</p>
        </div>

        <div className="" onClick={()=>NextIndex()}>
          <NavsButtonNext />
        </div>

      </div>
    </div>

  </>
  )
}