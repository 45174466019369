
import WebbDivider from "../webb/webb-divider";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionDark from "../webb/head-section-dark";
import { useState } from "react";
import { useEffect } from "react";
import { AccountDetails, AccountSubscriptionDetails, GetUserSubscriptionRate } from "../../services/srvc-users-realm";
import { BaseAccountDetails, BaseAccountBalance, CreateBaseAccount } from "../../services/srvc-accounts-realm";
import { UserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser, GetProductionUser, GetSandboxUser } from "../../services/srvc-auth-user";
import WebbSpinText from "../webb/webb-spintext";

export default function UserOffers () {

  const asset = UserForm() === "sn" ? GetSandboxUser() : GetProductionUser();


  const [loading, setLoading]=useState(true);
  const [info, setInfo] = useState({})
  const [subs, setSubs] = useState([])

  const [refresh, setRefresh] = useState(false)
 

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        
        const subsciption = await GetUserSubscriptionRate({
          data: {user: asset.user, form:asset.form}, 
          user: asset.user,
        })
       
        // console.log(subsciption)
        if(subsciption.data!= false)
        { 

          setSubs(subsciption.data.subs.filter(item => item.actv))
          setInfo(subsciption.data.meta)
        }
        // 
        

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user]);



  if (loading){ return ( <> <WebbSpinText /> </> ) }

  return (
    <>
      
      
      <WebbDividerSmall />

      <HeaderSectionDark data={{name: 'Subscription Rate'}}/>
      <div className={`d-flex border rounded-wd py-3`}>
        <div className="ms-3">
          {
            subs.map(item=>{
              return(
                <p className="text-normal">{item.name}</p>
              )
            })
          }
        </div>
        <div className="ms-auto me-3 text-end">
        {
            subs.map(item=>{
              return(
                <p className="text-normal">{parseFloat(item.rate)/1000000}</p>
              )
            })
          }
        </div>
      </div>
      <WebbDividerSmall />

      

    </>
  )
}