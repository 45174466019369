// vault
import { useEffect, useState } from "react";

import { UserForm } from "../../services/srvc-utilities";
import { GetSandboxUser, GetProductionUser } from "../../services/srvc-auth-user";
import { GetApiSummary } from "../../services/srvc-api-realm";

// import { docxGetSummary, GetUserBalance } from "../../services/srvc-smart-realm";

const list = [
  { name: 'Total', colr:'primary', icon: '', code:'total', cntr: '***' },
  { name: 'Success', colr:'success', icon: '', code:'success', cntr: '***' },
  { name: 'Pending', colr:'warning', icon: '', code:'wait', cntr: '***' },
  { name: 'Failed', colr:'danger', icon: '', code:'failed', cntr: '***' }
]

export default function UserApiSummary() {

  const asset = UserForm() === "sn" ? GetSandboxUser() : GetProductionUser();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState()

  // console.log(asset)

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        const datx = {
          data: { scrt: asset.user,form: asset.form },
          user: asset.user
        }
        // console.log(JSON.stringify(datx))
        var res = (await GetApiSummary(datx)).data
        // console.log(res)
        setData (res)
        
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user]);


  // console.log(data)
  return (
    <>
    <div className="row row-cols-4 row-cols-md-4 g-1 mb-3">

      {list && list.map((item, i) => (
        <div className="col" key={i}>
          <div className="back-color-lite p-2 px-3 rounded-wd" key={i}>

            <p className="m-0 p-0 text-small">
              <span><i className={`text-mini bi-circle-fill text-${item.colr}`}></i></span>
              <span>{' '}</span>
              <span className="text-uppercase">{item.name}</span>
            </p>

            <p className={`m-0 p-0 caption-sm ${data ? '': 'text-tone'}`}>{data && data[item.code] || '0'}</p>

          </div>
        </div>
      ))}

      </div>

    </>
  )
}