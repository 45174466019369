//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionDark from "../content/webb/head-section-dark";
import HeaderSectionLite from "../content/webb/head-section-lite";
import WebbDateTime from "../content/webb/webb-datetime";


import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { UserForm } from "../services/srvc-utilities";
import { GetProductionUser, GetSandboxUser } from "../services/srvc-auth-user";
import ApilistModule from "../content/api/apilist";
import TransferListModule from "../content/transfers/transfer-list";


const listTabs = [
  {name: 'Success', code: 'done'},
  {name: 'In Process', code: 'next'},
  {name: 'Failed', code: 'fail'}
]


export default function TransferListView() {
  const asset = UserForm() === "sn" ? GetSandboxUser() : GetProductionUser();
  const history= useHistory()

  const [sort, setSort] = useState('done')
  const [search, setSearch] = useState({})
  const [loading, setLoading] = useState(true)
  const [checked,setChecked] = useState(true)

 



  const handleSearch = async(item) => {
    //console.log('srch: ', sort)
   // console.log(item)
    setSearch(item);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Service Charges</strong> </>,
      data: 
        <> 
          
          <WebbDivider />
          <WebbDividerSmall/>

          
        </>,
      foot: <> <WebbDateTime/> </>,
    }}
    main=   
      {{      
        head: <>Transfers List</>,
        data: 
          <> 
            {/* navs */}
            <div className="d-flex justify-content-start">
              {listTabs && listTabs.length > 0 && listTabs.map((item, i) => ( 
                
                <span className={`text-mini text-uppercase p-2 px-3 me-1 rounded-wd text-center cursor
                  ${sort === item.code ? 'back-color-next text-color-wite': 'back-color-lite text-color-tone'}`}
                  key={i} style={{fontSize:'0.7rem'}} 
                  onClick={() => setSort(item.code)}
                  >{item.name}</span>

              ))}
         

            </div>
                        
            <WebbDividerMedium />

            <HeaderSectionDark data={{name: 'Transfers & Status'}}/> 
            
           <TransferListModule/>


            <WebbDividerMedium />
            <WebbDividerMedium />
          

          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: <> Activity </>,
        data: 
          <>
            
            
            
            <WebbDividerMedium />
            
            {/* <HeaderSectionLite data={{name: 'Scheduled Transfers'}}/> */}
            {/* <TransferListScheduleModule /> */}

          </>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}