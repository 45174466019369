//main
import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";
import HeaderSectionDark from "../content/webb/head-section-dark";
import WebbDateTime from "../content/webb/webb-datetime";


import { UserForm } from "../services/srvc-utilities";

import { useState } from "react";

import { useHistory } from "react-router-dom";
import { GetProductionUser, GetSandboxUser } from "../services/srvc-auth-user";
import UserInfoModule from "../content/home/user-infocard";
import UserTasksModule from "../content/home/user-tasks";
import AccountsBalanceModule from "../content/account/account-balance";
import UserApiSummary from "../content/home/api-summary";


const offr = 'https://img.freepik.com/free-vector/business-people-signing-online-contract-with-electronic-sign_74855-4379.jpg?size=338&ext=jpg'

export default function HomeSnbx() {


  const asset = UserForm() === "sn" ? GetSandboxUser() : GetProductionUser();

  const [loading, setLoading] = useState(true)

  const history = useHistory()


  // useEffect( () => {
  //   setLoading(true)
  //   if (asset){
  //     const fetchData = async() => {
  //       const result = (await AccountBalance({ 
  //         data: { user: asset.user, type: "base-account" },
  //         user: asset.user
  //       })).data;
  //       // console.log(result)
  //       if(result==false)
  //       {
  //         history.push(`/${asset.form.substring(0,2)}/accounts/subscription`)
  //         return
  //       }
  //       if( parseFloat(result.balance.number)/1000000 < 100)
  //       {
  //         history.push(`/${asset.form.substring(0,2)}/accounts/subscription`)
  //         return
  //       }
  //       // setAcnt(result)
  //       setLoading(false)
  //     }
  //     fetchData()
  //   } else {}
  // },[]);
  
  // if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-color-tone">Home</strong> </>,
      data: 
        <> 
         <UserInfoModule />
         <WebbDivider />
         <AccountsBalanceModule />
         <WebbDividerSmall />
         
         {/* <UserTasksModule /> */}
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        head: <> Summary and Actions </>,
        data: 
          <> 

          <HeaderSectionDark data={{name: 'API Summary'}}/>
          <UserApiSummary />
 
          <WebbDividerSmall />
          <WebbDivider />

          <WebbDividerMedium />
         
        
          <WebbDividerMedium />



          <WebbDividerMedium />
          
        </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: <>Activity </>,
        data: 
          <>
            {/* <HeaderSectionLite data={{name: 'Promotions'}}/>
            <img src={offr} alt='...' className="img-fluid border"></img>
            <WebbDividerSmall />
            <p>Open a New Account and Get first Contract Free</p> */}
            
            
          </>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}