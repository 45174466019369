import React from 'react'
import { UserForm } from '../../services/srvc-utilities';
import { GetProductionUser, GetSandboxUser } from '../../services/srvc-auth-user';

const CredentialViewModule = () => {

const asset = UserForm() === "sn" ? GetSandboxUser() : GetProductionUser();
// console.log(asset)

var auth = asset.form=="sn"? JSON.parse(localStorage.getItem("snbx")): JSON.parse(localStorage.getItem("prod"))
//console.log(auth)

  return (
    <div style={{maxWidth:"100%", marginLeft:"0", marginRight:"auto" , }} className='rounded border p-3'>
        <div className='d-flex'>
        <p className='me-auto'>Name</p>
        <p className='text-end'>{auth.name}</p>
        </div>

        <div className='d-flex'>
        <p className='me-auto'>Mail</p>
        <p className='text-end'>{auth.mail}</p>
        </div>

        <div className='d-flex'>
        <p className='me-auto'>User Id</p>
        <p className='text-end'>{auth.user}</p>
        </div>

        <div className='d-flex'>
        <p className='me-auto'>Secret Id</p>
        <p className='text-end'>{auth.secret}</p>
        </div>

        <div className='d-flex'>
        <p className='me-auto'>Service Id</p>
        <p className='text-end'>{auth.user}</p>
        </div>
        
    </div>
  )
}

export default CredentialViewModule