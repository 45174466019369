import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

//views - main
import Main from '../viewsxz/main';
// import Land from "../viewsxz/land";

// views - auth - onbd
import AuthMobileFirebase from "../viewsxa/auth-mobile-firebase";
import ANewMobileFirebase from "../viewsxa/anew-mobile-firebase";
import AuthMailFirebase from "../viewsxa/auth-mail-firebase";
import AuthMailCheckFirebase from "../viewsxa/auth-mail-check";
import AuthNext from "../viewsxa/auth-next";
import AuthSessionX from "../viewsxa/auth-session-x";
import HomeSnbx from "../viewsxx/home-snbx";
import ApiListView from "../viewsap/apilist";
import TransferListView from "../viewsxt/transferlist";
import HomeProd from "../viewsxx/home-prod";
import CredentialView from "../viewscd/credentials";


const routes = [

  { path:'/', component: Main, auth:false },


  { path:'/auth/mail', component: AuthMailFirebase, auth:false },
  { path:'/auth/mail/check', component: AuthMailCheckFirebase, auth:false },
  { path:'/auth/next', component: AuthNext, auth:true },
  { path:'/auth/x', component: AuthSessionX, auth:true },

  { path:'/account/access', component: AuthMobileFirebase, auth:false },
  { path:'/account/create', component: ANewMobileFirebase, auth:false },

  //------------------------------sandbox route-------------------------------------

  { path:'/sn/home', component: HomeSnbx, auth:false },
  { path:'/sn/calls', component: ApiListView, auth:false },
  { path:'/sn/cost', component: TransferListView, auth:false },
  { path:'/sn/cred', component: CredentialView, auth:false },

  //------------------------------production route-------------------------------------

  { path:'/pr/home', component: HomeProd, auth:false },
  { path:'/pr/calls', component: ApiListView, auth:false },
  { path:'/pr/cost', component: TransferListView, auth:false },
  { path:'/pr/cred', component: CredentialView, auth:false },
  
  
 
]

const PrivateRoute = (props) => {
  const location = useLocation();
  //console.log (location.pathname)
  const { user } = useAuth();
  return user 
  ? ( <Route { ...props } /> ) 
  : ( <Redirect to={{ pathname: "/", state: { from: location } }} /> )
};

export default function Routes() {

  return (
    <Switch>
      {routes.map ((item,i)=>(item.auth
      ? <PrivateRoute
          key={i}
          path={item.path}
          component={item.component}
          exact
        />
      : <Route
          key={i}
          path={item.path}
          component={item.component}
          exact
        />
      ))}
    </Switch>
  );
}