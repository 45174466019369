import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"; 

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import NextIntro from "../webb/next-intro";
import NextDivider from "../webb/next-divider";
import NextLogout from "../webb/next-logout";
import NextAccounts from "../webb/next-accounts";
import NextBusiness from "../webb/next-business";

import { GetAuthUser, SetSandboxUser, SetProductionUser } from '../../services/srvc-auth-user';
import { GetProductionUserAccounts, GetSandboxUserAccounts } from "../../services/srvc-api-realm";



export default function AuthNextModule () {

  const asset = GetAuthUser();
  // console.log(asset)
  

  const [sandboxuser, setSandboxUser] = useState({});
  const [produser, setProdUser] = useState({})
  const [loading,setLoading] = useState(true);

  const history = useHistory();

  localStorage.removeItem("prod")
  localStorage.removeItem("snbx")

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        var res = (await GetSandboxUserAccounts({data:{mail:asset.user}, user:asset.user})).data
        // console.log(res)
        setSandboxUser(res)

        var prod = (await GetProductionUserAccounts({data:{mail:asset.user}, user:asset.user})).data
        // console.log(prod)
        setProdUser(prod)

        setLoading(false)
      }
      fetchData()
    } else {}
  },[]);


  const nextuseraction = (usxx) => {
    // console.log(usxx)
    if (usxx.active) {
      usxx.form === 'sn' 
        ? SetSandboxUser (usxx)
        : SetProductionUser (usxx)
        
        history.push (`/${usxx.form.substring(0,2)}/home`);
    }
    // else onboardUser (usxx)
  }

  if (loading) 
  return (<> <WebbLoader /> </>)
  

  
  
  return (
    <>
    {/* info  */}
    <WebbDividerSmall />
    <WebbModuleInfo data={{text:'Please select an account to continue'}} />
    <WebbDividerSmall />

    {/* personal */}
    <NextAccounts 
      data={[sandboxuser]}
      form={'Sandbox'}
      user={nextuseraction}
    />
    
    <WebbDividerMedium />
    {/* business */}
    <NextAccounts
      data={[produser]} 
      form={'Production'}
      user={nextuseraction}
    />

    {/* actions */}
    <WebbDividerMedium />
    <NextDivider />
   {/* <div className="" onClick={() => newBusiness()}>
      <NextBusiness />
    </div>  */}
    

    <WebbDividerSmall />
    <NextLogout />
    
    
  </>
  )
  
}