// init
import axios from "axios";
const sandbox = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-srvc-qyayc/endpoint"
const production = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-srvc-exzfo/endpoint"

// Function Calls

export const GetApiSummary = async (item) => {
   
  var url = item.data.form=="sn"?sandbox:production

  const basx = url + '/api/summary';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}
  // console.log(datx)
  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const GetApiTransactionList = async (item) => {
   
  var url = item.data.form=="sn"?sandbox:production

  const basx = url + '/api/transaction/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}
  // console.log(datx)
  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const GetSandboxUserAccounts = async (item) => {

  const basx = sandbox + '/api/get/user';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}
  // console.log(datx)
  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const GetProductionUserAccounts = async (item) => {

  const basx = production + '/api/get/user';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}
  // console.log(datx)
  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}