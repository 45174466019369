// user info

import { UserForm } from "../../services/srvc-utilities";
import { GetSandboxUser, GetProductionUser } from "../../services/srvc-auth-user";

export default function UserInfoModule() {

  const asset = UserForm() === "sn" ? GetSandboxUser() : GetProductionUser();
  // console.log(asset)

  return (
  <>
    <p className="text-normal text-bold m-0 p-0 text-sm">{asset.name}</p>
    <p className="text-small m-0 p-0">{asset.mail}</p>
    <p className="text-small text-color-tone">{asset.form === 'sn' ? 'Sandbox' : 'Production'} Account</p>
  </>
  )
}